// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "sortable-tablesort/sortable.min.js";

const TABLE = document.querySelector(".document-table");
const SORTS = document.querySelectorAll(".document-table__details__input");

if (TABLE && SORTS.length > 0) {
    SORTS.forEach((sort) => {
        sort.addEventListener("click", () => {
            document.getElementById(sort.dataset.target).click();
        });
    });
}
