// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "mdn-polyfills/NodeList.prototype.forEach";

const SEARCH_TOGGLES = document.querySelectorAll("[data-toggle*='__search']");

SEARCH_TOGGLES.forEach((toggle) => {
    const SEARCH_FORM     = toggle ? document.getElementById(toggle.dataset.toggle) : false;
    const SEARCH_INPUT    = SEARCH_FORM ? SEARCH_FORM.querySelector("input[type=search]") : false;
    const SEARCH_ELEMENTS = SEARCH_FORM ? SEARCH_FORM.querySelectorAll("*") : false;

    if (SEARCH_FORM && SEARCH_INPUT && SEARCH_ELEMENTS) {
        toggle.addEventListener("click", (e) => {
            e.preventDefault();

            SEARCH_FORM.classList.add("is-active");
            document.documentElement.classList.add("search-active");
            SEARCH_INPUT.focus();
        });

        SEARCH_ELEMENTS.forEach((element) => {
            element.addEventListener("blur", () => {
                setTimeout(() => {
                    if (!SEARCH_FORM.contains(document.activeElement)) {
                        SEARCH_FORM.classList.remove("is-active");
                        document.documentElement.classList.remove("search-active");
                        toggle.focus();
                    }
                }, 100);
            }, { passive: true });
        });
    }
});

const DETAILS = document.querySelectorAll(".search-form__details");

DETAILS.forEach((detail) => {
    const SUMMARY = detail.querySelector("summary");
    const RADIOS  = detail.querySelectorAll("input[type=radio]");

    RADIOS.forEach((radio) => {
        radio.addEventListener("change", () => {
            detail.removeAttribute("open");
            SUMMARY.innerHTML = radio.nextElementSibling.innerHTML;
        });
    });
});
